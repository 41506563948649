import ReactOnRails from "react-on-rails";
import CookieConsent from "~/react/components/smartevents/shared/CookieConsent";
import Footer from "~/react/components/smartevents/shared/Footer";
import Footer367n from "~/react/components/default/shared/Footer367n";
import Header from "~/react/components/smartevents/shared/Header";
import TrackingConsent from "~/react/components/smartevents/shared/TrackingConsent";
import Header367n from "~/react/templates/shared/Header367n";

ReactOnRails.register({
  CookieConsent,
  Footer,
  Footer367n,
  Header,
  TrackingConsent,
  Header367n,
});
